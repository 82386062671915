import { Getter } from "vuex";
import { IState } from './state';

const name: Getter<IState, IState> = (state) => state.name;
const description: Getter<IState, IState> = (state) => state.description;
const action: Getter<IState, IState> = (state) => state.action;

export default {
	name,
	description,
	action
}
