


















import Vue from 'vue'
import { IAction } from '../store/state';

interface CardComputed {
	name: string,
	description: string,
	action: IAction
}

export default Vue.extend<{}, {}, CardComputed, {}>({
	name: 'card',
	computed: {
		name() { return this.$store.getters.name },
		description() { return this.$store.getters.description },
		action() { return this.$store.getters.action }
	}
})
