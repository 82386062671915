export interface IState {
	name: string,
	description: string,
	action: IAction
}

export interface IAction {
	name: string,
	label: string,
	instructions: string,
	url: string,
	isFile: boolean
}

const deafultState: IState = {
	name: '',
	description: '',
	action: {
		name: '',
		label: '',
		instructions: '',
		url: '',
		isFile: false
	}
}

export default deafultState;
